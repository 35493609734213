import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
function Footer() {
  return (
    <div className="row footer flex">
      <div className="row footer_content container flex">
        <div className="col-md-6 footer_text_wrap ">
          <div className="col-md-4 col-2 footer_text main">
            <Link to="/about">About us</Link>
            <Link to="/about">Contact</Link>
            <Link to="/">Home</Link>
            <Link to="/storage-rakings">Product</Link>
            <Link to="/blog">News</Link>
          </div>
          <div className="col-md-8 col-10 footer_text footer_new">
            <h1>ALWANUSHUROOQ TRADING EST.</h1>

            <p>
              <a href="mailto:info@astshelving.com">info@astshelving.com </a>{" "}
              <br />
              <a href="mailto:sales@astshelving.com"> sales@astshelving.com</a>
              <br />
              <a href="mailto:hashim@astshelving.com">
                {" "}
                hashim@astshelving.com
              </a>
              <br />
            </p>

            <p>
              Abu Dhabi & Al Ain: <br />
              <a href="tel:+971 055 2429511">055 2429511 or 055 2427521</a>
            </p>
            <p>
              Dubai & Northern Emirates: <br />
              <a href="tel:+971 055 1954778">055 1954778</a>
            </p>
          </div>
        </div>

        <div className="col-md-3 col-4 footer_text icon ">
          <h1>FOLLOW US </h1>
          <div className="footer_icons flex">
            <div className="react__icon">
              <a
                href="https://www.facebook.com/astshelving"
                title="astshelving facebook"
              >
                <FaFacebookF id="fb__icon" size="1.3rem" title="facebook" />
              </a>
            </div>
            <div className="react__icon">
              <a
                href="https://www.instagram.com/astshelving/"
                title="astshelving instagram"
              >
                <FaInstagram id="In__icon" size="1.3rem" title="instagram" />
              </a>
            </div>
            <div className="react__icon">
              <a href="https://wa.me/971552429511" title="astshelving whatsapp">
                <FaWhatsapp id="Ln__icon" size="1.3rem" title="whatsapp" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 footer_foot">
        <p>
          {new Date().getFullYear()} ©{" "}
          <Link
            to="!#"
            title="ALWANUSHUROOQ TRADING EST"
            style={{ cursor: "pointer" }}
          >
            Alwanushurooq
          </Link>{" "}
          . All Rights Reserved | Developed by{" "}
          <a title="osperb innovations" href="https://osperb.com">
            osperb
          </a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
