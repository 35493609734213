import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import NavBar from "../../layout/NavBar";
import Footer from "../../layout/Footer";
import "./categoryPage.css";

// Backend imports
import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getProducts } from "../../../actions/productActions";
import { loadSettings } from "../../../actions/settingsActions";

const CategoryPageAll = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { loading, error, products } = useSelector((state) => state.products);
  const { settings } = useSelector((state) => state.settings);

  useEffect(() => {
    if (error) {
      return alert.error(error);
    }

    if (!products) {
      dispatch(getProducts());
    }

    dispatch(loadSettings());
  }, [dispatch, alert, error, products]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title="storage-rakings" />
          <NavBar BrownTheme allproduct />
          <section className="product__scarf">
            <section
              className="mail_section alptd flex"
            >
               <img
                  alt="Heavy duty mezzanine storage shelvings in warehouse"
                  src="/images/Ast/Mobile-shelving-and-moving-racking-in-uae.jpg"
                />
            

              <div className="fade__bg fade__bg_product">
                <label htmlFor="">
                  <h4>All Products</h4>
                </label>
              </div>
            </section>
            <div className="product_list">
              {/* product cards section start */}
              <section className="scraf__section">
                <div className="card__contianer center_align">
                  <div className="row col-lg-12">
                    {products &&
                      products.map((product, key) => (
                        <div className="col col-md-3 col-6 card_wrap" key={key}>
                          <div className="card__img">
                            <Link to={`/product/${product._id}`}>
                              <img
                                src={
                                  product.images[0]
                                    ? `${process.env.REACT_APP_BASE_URL}${product.images[0].url}`
                                    : "/images/others/default_product.png"
                                }
                                alt={product.name}
                              />
                            </Link>
                          </div>
                          {product?.images[1] && (
                              <div className="card__img_second">
                                <Link to={`/product/${product._id}`}>
                                  <img
                                    src={
                                      product.images[1]
                                        ? product.images[1].url
                                        : ""
                                    }
                                    alt={product.name}
                                  />
                                </Link>
                              </div>
                            )}
                          <div>
                            <div className="product_name_wrap">
                              <div className="product_name">
                                <h4>{product.name}</h4>
                                <p>
                                  {settings && settings.defaultCurrency}{" "}
                                  {product.priceTypes[0].unitPrice}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* product cards section end */}
            </div>
          </section>
          <Footer />
        </>
      )}
    </Fragment>
  );
};

export default CategoryPageAll;
