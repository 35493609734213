export const NEW_ENQUIRY_REQUEST = 'NEW_ENQUIRY_REQUEST'
export const NEW_ENQUIRY_SUCCESS = 'NEW_ENQUIRY_SUCCESS'
export const NEW_ENQUIRY_RESET = 'NEW_ENQUIRY_RESET'
export const NEW_ENQUIRY_FAIL = 'NEW_ENQUIRY_FAIL'

export const NEW_ADMIN_ENQUIRY_REQUEST = 'NEW_ADMIN_ENQUIRY_REQUEST'
export const NEW_ADMIN_ENQUIRY_SUCCESS = 'NEW_ADMIN_ENQUIRY_SUCCESS'
export const NEW_ADMIN_ENQUIRY_RESET = 'NEW_ADMIN_ENQUIRY_RESET'
export const NEW_ADMIN_ENQUIRY_FAIL = 'NEW_ADMIN_ENQUIRY_FAIL'

export const ADMIN_ENQUIRIES_REQUEST = 'ADMIN_ENQUIRIES_REQUEST'
export const ADMIN_ENQUIRIES_SUCCESS = 'ADMIN_ENQUIRIES_SUCCESS'
export const ADMIN_ENQUIRIES_FAIL = 'ADMIN_ENQUIRIES_FAIL'

export const DELETE_ENQUIRY_REQUEST = 'DELETE_ENQUIRY_REQUEST'
export const DELETE_ENQUIRY_SUCCESS = 'DELETE_ENQUIRY_SUCCESS'
export const DELETE_ENQUIRY_RESET = 'DELETE_ENQUIRY_RESET'
export const DELETE_ENQUIRY_FAIL = 'DELETE_ENQUIRY_FAIL'

export const UPDATE_ENQUIRY_REQUEST = 'UPDATE_ENQUIRY_REQUEST'
export const UPDATE_ENQUIRY_SUCCESS = 'UPDATE_ENQUIRY_SUCCESS'
export const UPDATE_ENQUIRY_RESET = 'UPDATE_ENQUIRY_RESET'
export const UPDATE_ENQUIRY_FAIL = 'UPDATE_ENQUIRY_FAIL'

export const ENQUIRY_DETAILS_REQUEST = 'ENQUIRY_DETAILS_REQUEST'
export const ENQUIRY_DETAILS_SUCCESS = 'ENQUIRY_DETAILS_SUCCESS'
export const ENQUIRY_DETAILS_FAIL = 'ENQUIRY_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'