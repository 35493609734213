import React, { useEffect, useState } from "react";
import { Link,  } from "react-router-dom";
import "./navbar.css";
// react icons
import { FaPhoneVolume } from "react-icons/fa";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

// Backend Imports
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../actions/userActions";

import { getCategories } from "../../actions/categoryActions";
import { getProducts } from "../../actions/productActions";
import {getBlogs} from "../../actions/blogActions"
import { loadSettings } from "../../actions/settingsActions";
import { Navbar,NavLink } from "react-bootstrap";
const NavBar = ({ homepg, BrownTheme }) => {
  // state for  navbar scroll change update
  const [scroll, setScroll] = useState(false);

  const alert = useAlert();
  const dispatch = useDispatch();

  const { isAuthenticated, error, isLoggedIn } = useSelector(
    (state) => state.auth
  );

  const { suggestedProducts, products } = useSelector(
    (state) => state.products
  );

  const { blogs } = useSelector((state) => state.blogs);
  
  const listnerFunction = () => {
    if (window.scrollY > 50) {
      setScroll(true);
    } else if (window.scrollY <= 100) {
      setScroll(false);
    }
  }

  useEffect(() => {
    dispatch(getBlogs())
    dispatch(getCategories());
    if (!suggestedProducts) {
      dispatch(getProducts());
    }
    dispatch(loadSettings());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    window.addEventListener("scroll", listnerFunction);

    return () => {
      window.removeEventListener("scroll", listnerFunction);
    };

  }, [dispatch, alert, isAuthenticated, isLoggedIn, error]);

  const Icon = () => {
    return (
      <div className="arrow_wrapper">
        <BiChevronDown className="down" />
        <BiChevronUp className="up" />
      </div>
    );
  };

  return (
    <>
      {/* NAVBAR START */}
      <div
        className={`navbar_wrap ${scroll === true && "active"} ${
          BrownTheme && "navBrown"
        }`}
      >
        <div className="row nav_row">
          <div className="col-md-3 col-4 layamer_logo flex">
            <Link to="/" className="layamer_logo_wrap">
            <img
              src="/images/logo/AST-Shelving.png"
              alt="mahalo_brown"
            />
            
            </Link>
          </div>

          <div className="col-md-6 col-4 navbar_icon_gp flex">
            <ul className="menu__bar_ul main">
              <Link to="/">
                <li>Home</li>
              </Link>
              
                <li className="nav-link" id="product_link">
                <Link to="/storage-rakings"  >
                  Product <Icon />
                </Link>
                  <div className="mega-drop drop___container">
                    <div className="content drop___content">
                      <ul className="mega-link">
                        {products?.map((product, index) => (
                          <Link to={`/product/${product._id}`} key={index}>
                            <li>{product.name}</li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              

              
                <li className="nav-link">
                  News <Icon />
                  <div className="mega-drop drop___container">
                    {/* <Link to="" style={{ textDecoration: "none" }}> */}
                      <div className="content drop___content">
                        <ul className="mega-link">
                          {blogs?.map((blog, index) => (
                            <Link  to={`/blog/${blog.subTitle}`} key={index}>
                              <li>{blog.subTitle}</li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    {/* </Link> */}
                  </div>
                </li>
              
              <Link to="/about">
                <li>Contact</li>
              </Link>
            </ul>
          </div>

          <div className="col-md-3 col-4 bars_wrap flex">
            <a
              href="tel:055 2427521"
              title="Give me a call"
              className="queries"
            >
              <div className="qu_icon">
                <FaPhoneVolume />
              </div>
              <p> 055 2429511 </p>
            </a>
          </div>
        </div>

        {/* TODO: Use NavLink for link option inside Navbar Component */}
        {/* for smaller screen */}
        <Navbar
          className={`row nav__two navShow ${scroll && "actived"}`}
          bg="transperant"
          expand="lg"
        >
          <div className={`nav__wrap ${scroll && "actived"}`}>
            <ul className="menu__bar_ul">
              <Link to="/">
                <li>Home</li>
              </Link>
              
                <li className="nav-link" >
                <Link to="/storage-rakings" id="product__link" >
                  Products <Icon />
                </Link>
                <div className="mega-drop drop___container">
                    <div className="content drop___content">
                      <ul className="mega-link">
                        {products?.map((product, index) => (
                          <Link to={`/product/${product._id}`} key={index}>
                            <li>{product.name}</li>
                          </Link>
                        ))}
                      </ul>
                    </div>
                  </div>
                </li>
              

              
                <li className="nav-link">
                
                  News <Icon />
                  <div className="mega-drop drop___container">
                    {/* <Link to="!#" style={{ textDecoration: "none" }}> */}
                      <div className="content drop___content">
                        <ul className="mega-link">
                        {blogs?.map((blog, index) => (
                            <Link  to={`/blog/${blog.subTitle}`} key={index}>
                              <li>{blog.subTitle}</li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    {/* </Link> */}
                  </div>
                </li>
              

              <Link to="/about">
                <li>Contact</li>
              </Link>
            </ul>
          </div>
        </Navbar>
      </div>
      {/* NAVBAR END */}
    </>
  );
};

export default NavBar;
