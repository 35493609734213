import React, { Fragment, useEffect, useState } from "react";
import "./homepage.css";

import MetaData from "../../layout/MetaData";
import Loader from "../../layout/Loader";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getProducts } from "../../../actions/productActions";
import { getTestimonials } from "../../../actions/testimonialActions";

// modal
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

// Frontend imports
import { Link, useHistory } from "react-router-dom";
// slider css links
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// react icons
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import { ImQuotesRight } from "react-icons/im";
import NavBar from "../../layout/NavBar";
import Footer from "../../layout/Footer";
import ContactFrom from "../ContactFrom/ContactFrom";
import { motion } from "framer-motion";
import { getBlogs } from "../../../actions/blogActions";
import moment from "moment";

const HomePage = ({ match }) => {
  // modal
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const alert = useAlert();
  const dispatch = useDispatch();

  const { settings: systemSettings } = useSelector((state) => state.settings);

  const {
    loading,
    error,
    products,
    productsCount,
    featuredProducts,
    popularProducts,
  } = useSelector((state) => state.products);

  const {
    error: testimonialError,
    testimonials,
    testimonialsCount,
  } = useSelector((state) => state.testimonials);
  // const { error: updateError, isAdded } = useSelector((state) => state.cart);

  const { blogs } = useSelector((state) => state.blogs);

  useEffect(() => {
    let timer1 = setTimeout(() => setOpen(true), 5000);
    setTimeout(timer1);

    dispatch(getTestimonials());
    dispatch(getBlogs());

    if (error) {
      return alert.error(error);
    }

    if (testimonialError) {
      return alert.error(testimonialError);
    }

    if (!products) {
      dispatch(getProducts());
    }

    return () => {
      clearTimeout(timer1);
    };
  }, [dispatch, alert, error, products, testimonialError]);

  // Frontend --------------------------------

  // slider options
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => <ul>{dots}</ul>,
  };
  const BlogSettings = {
    prevArrow: <PrevBgArrow />,
    nextArrow: <NextBgArrow />,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: "3%",
    pauseOnHover: false,
    lazyLoad: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  });

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData
            title={"Advanced Storage racking solutions in UAE"}
            name={"description"}
            content={
              "Offering warehouse pallet racking, office mobile shelvings and Storage racking to improving the storage capabilities of warehouses and Offices"
            }
          />
          <NavBar homepg />

          {/* modal satrt */}
          <Modal open={open} onClose={onCloseModal} center id="new_modal">
            <ContactFrom isHidden isModal setOpen={setOpen} />
          </Modal>
          {/* modal end */}

          {/* HERO SECTION START */}
          <section className="hero_section">
            {/* HERO SLIDER START */}
            <div className="hero__slider">
              <Slider {...settings}>
                <div className="slider_content_one">
                  <img
                    src="/images/Ast/pallet-racking-in-uae.jpg"
                    alt="Heavy duty pallet storage racking in warehouse"
                  />
                </div>
                <div id="slider_text" className="slider_content_one">
                  <img
                    src="/images/Ast/pallet-racking-in-abudabhi-uae.jpg"
                    alt="hero-section"
                  />
                </div>
              </Slider>
            </div>

            {/* HERO SLIDER END Advanced Storage racking solutions in UAE*/}
            <div className="hero__fade" />

            <motion.label
              className="drops"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, type: "spring", stiffness: 120 }}
            >
              <span className="txt__hover a">Advanced</span>
              <span className="txt__hover"> storage </span>
              <span className="txt__hover"> racking </span>
              <span className="txt__hover b">solutions </span>
              <span className="txt__hover e">in UAE</span>
              <p className="hero_text_new">
                Focusing on improving the storage capabilities of warehouses and
                Offices in UAE, We are offering warehouse pallet racking, Office
                mobile shelvings, mezzanine shelvings and slotted angle storage
                rackings
              </p>
            </motion.label>
          </section>
          {/* HERO SECTION END */}

          {/* featured products sections start */}
          {featuredProducts && featuredProducts.length > 0 && (
            <>
              <section className="product_section flex">
                <h1> Featured Products</h1>
                {/* <hr /> */}
                <div className="card__contianer center_align">
                  <div className="row col-lg-12">
                    {featuredProducts &&
                      featuredProducts.map((product, key) => (
                        <div
                          className="column col-md-3 col-6 card_wrap"
                          key={key}
                        >
                          <div className="card__img">
                            <Link to={`/product/${product._id}`}>
                              <img
                                src={
                                  product.images[0]
                                    ? `${process.env.REACT_APP_BASE_URL}${product.images[0].url}`
                                    : "/images/others/default_product.png"
                                }
                                alt={product.name}
                              />
                            </Link>
                          </div>
                          {product?.images[1] && (
                            <div className="card__img_second">
                              <Link to={`/product/${product._id}`}>
                                <img
                                  src={
                                    product.images[1]
                                      ? `${process.env.REACT_APP_BASE_URL}${product.images[1].url}`
                                      : ""
                                  }
                                  alt={product.name}
                                />
                              </Link>
                            </div>
                          )}
                          <div className="product_name_div">
                            <div className="product_name">
                              <Link to={`/product/${product._id}`}>
                                <h4>{product.name}</h4>
                                <h4>
                                  {systemSettings?.defaultCurrency}{" "}
                                  {product?.priceTypes[0]?.unitPrice}
                                </h4>
                                {/* <p>Enquire now</p> */}
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* featured products sections end */}
              {/* banner 1 SECTION START */}
              <section className="mail_section flex">
                <img
                  alt="Heavy duty mezzanine storage shelvings in warehouse"
                  src="/images/Ast/Mobile-shelving-and-moving-racking-in-uae.jpg"
                />
                <div className="fade__bg">
                  <label
                    htmlFor="
        "
                  >
                    <h4>Optimised spaces and</h4>
                    <h1>intelligent storage</h1>
                  </label>
                </div>
              </section>
            </>
          )}
          {/* banner 1 SECTION END */}

          {/* popular products sections start */}
          {popularProducts && popularProducts.length > 0 && (
            <>
              <section className="product_section flex">
                <h1> Popular Products</h1>
                {/* <hr /> */}
                <div className="card__contianer center_align">
                  <div className="row col-lg-12">
                    {popularProducts &&
                      popularProducts.map((product, key) => (
                        <div
                          className="column col-md-3 col-6 card_wrap"
                          key={key}
                        >
                          <div className="card__img">
                            <Link to={`/product/${product._id}`}>
                              <img
                                src={
                                  product.images[0]
                                    ? `${process.env.REACT_APP_BASE_URL}${product.images[0].url}`
                                    : "/images/others/default_product.png"
                                }
                                alt={product.name}
                              />
                            </Link>
                          </div>

                          {product?.images[1] && (
                            <div className="card__img_second">
                              <Link to={`/product/${product._id}`}>
                                <img
                                  src={
                                    product.images[1]
                                      ? `${process.env.REACT_APP_BASE_URL}${product.images[1].url}`
                                      : ""
                                  }
                                  alt={product.name}
                                />
                              </Link>
                            </div>
                          )}

                          <div className="product_name_div">
                            <div className="product_name">
                              <Link to={`/product/${product._id}`}>
                                <h4>{product.name}</h4>
                                <h4>
                                  {systemSettings?.defaultCurrency}{" "}
                                  {product?.priceTypes[0]?.unitPrice}
                                </h4>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
              {/* popular products sections end */}
              {/* banner 2 SECTION START */}

              <section className="mail_section two flex">
                <img
                  src="/images/Ast/Cantilever-platform-or-mezzanine-rack-systems-in-dubai-uae.jpg"
                  alt="banner-background"
                />
                <div className="fade__bg two">
                  <label
                    htmlFor="
        "
                  >
                    <h4>Advanced Warehouse </h4>
                    <h1>storage solutions</h1>
                  </label>
                </div>
              </section>
            </>
          )}
          {/* banner 2 SECTION END */}

          {/* new arrivals sections start */}
          {products && products.length > 0 && (
            <>
              <section className="product_section flex">
                <h1> New Arrivals</h1>
                {/* <hr /> */}
                <div className="card__contianer center_align">
                  <div className="row col-lg-12">
                    {products &&
                      products
                        .slice(productsCount < 4 ? 0 : productsCount - 4, 6)
                        .map((product, key) => (
                          <div
                            className="column col-md-3 col-6 card_wrap"
                            key={key}
                          >
                            <div className="card__img">
                              <Link to={`/product/${product._id}`}>
                                <img
                                  src={
                                    product.images[0]
                                      ? `${process.env.REACT_APP_BASE_URL}${product.images[0].url}`
                                      : "/images/others/default_product.png"
                                  }
                                  alt={product.name}
                                />
                              </Link>
                            </div>
                            {product?.images[1] && (
                              <div className="card__img_second">
                                <Link to={`/product/${product._id}`}>
                                  <img
                                    src={
                                      product.images[1]
                                        ? `${process.env.REACT_APP_BASE_URL}${product.images[1].url}`
                                        : ""
                                    }
                                    alt={product.name}
                                  />
                                </Link>
                              </div>
                            )}

                            <div className="product_name_div">
                              <div className="product_name">
                                <Link to={`/product/${product._id}`}>
                                  <h4>{product.name}</h4>
                                  <h4>
                                    {systemSettings?.defaultCurrency}{" "}
                                    {product?.priceTypes[0]?.unitPrice}
                                  </h4>

                                  {/* <p>Enquire now</p> */}
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </section>
              {/* new arrivals sections end */}
            </>
          )}
          {/* banner 1 SECTION END */}

          {/* Testnomials section starts */}
          {testimonials && testimonialsCount > 0 && (
            <section className="testimonial">
              <Slider {...settings}>
                {testimonials &&
                  testimonials.map((testimonial, key) => (
                    <div id="tm__slider__wrap" key={key}>
                      <div className="quotation__mark">
                        <ImQuotesRight />
                      </div>
                      <div className="quote__text">
                        <p>{testimonial.message}</p>
                      </div>
                      <div className="tm__images">
                        <img
                          src={
                            testimonial.images[0]
                              ? `${process.env.REACT_APP_BASE_URL}${testimonial.images[0].url}`
                              : "/images/others/default_product.png"
                          }
                          alt={testimonial.name}
                        />
                      </div>
                      <h1>{testimonial.name}</h1>
                    </div>
                  ))}
              </Slider>
            </section>
          )}
          {/* Testnomials section ends */}
          {/* Blog section start */}
          {blogs && blogs.length > 0 && (
            <section className="Blog_new">
              <div className="fading">
                <h1>Latest News</h1>
              </div>
              <Slider {...BlogSettings}>
                {blogs?.map((blog, index) => (
                  <div className="blog_card" key={index}>
                    <h6 className="blog__header">
                      {moment(blog?.date).format("DD-MM-YYYY")}
                    </h6>
                    <h1 className="blog__title">{blog?.title}</h1>
                    <div className="blog__img">
                      <Link to={`/blog/${blog?.subTitle}`}>
                        {blog?.images[0]?.url ? (
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}${blog?.images[0]?.url}`}
                            alt="blogs"
                          />
                        ) : (
                          <img src="/images/Ast/defualt.png" alt="blogs" />
                        )}
                      </Link>
                    </div>
                    <Link to={`/blog/${blog?.subTitle}`} className="btn mt-2">
                      View details
                      <IoMdArrowDropright />
                    </Link>
                  </div>
                ))}
              </Slider>
            </section>
          )}
          {/* Blog section end */}

          {/* contact form start */}
          <ContactFrom ishomeBottom />

          {/* contact form end */}

          <Footer />
        </Fragment>
      )}
    </Fragment>
  );
};

export default HomePage;

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <IoIosArrowBack
      className={"react__arrowPrev"}
      onClick={onClick}
      style={{ ...style, display: "block", zIndex: "8" }}
    />
  );
}
function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <IoIosArrowForward
      className={"react__arrowNext"}
      style={{ ...style, display: "block", zIndex: "8" }}
      onClick={onClick}
    />
  );
}
function PrevBgArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="arrowBgPrev wrap">
      <IoIosArrowBack
        className={"arrowBgPrev"}
        onClick={onClick}
        style={{ ...style, display: "block", zIndex: "8" }}
      />
    </div>
  );
}
function NextBgArrow(props) {
  const { style, onClick } = props;
  return (
    <div className="arrowBgNext wrap">
      <IoIosArrowForward
        className={"arrowBgNext"}
        style={{ ...style, display: "block", zIndex: "8" }}
        onClick={onClick}
      />
    </div>
  );
}
