import React from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title,content,name }) => {
  return (
    <Helmet>
      <title>{`Alwanu Shurooq | ${title}`}</title>
      <meta name={`${name}`} content={`${content}`}/>
      <link rel="canonical" href="/" />
      <link rel="icon" type="image/png" href="/images/logo/favicon.ico" sizes="16x16" />
      <meta name="keywords" content="steel, storage ,racking , shelvings ,slotted angle"></meta>
    </Helmet>
  );
};

export default MetaData;
