//react default pkg
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";

//css
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

//supports
import ProtectedRoute from "./components/route/ProtectedRoute";
import { loadUser } from "./actions/userActions";
import { AppContext } from "./AppContext";
import PreLoader from "./components/layout/PreLoader/PreLoader";
import ScrollTop from "./components/layout/Scroll/scrollTop";

//Mahalo Pages
import HomePage from "./components/pages/homePage/Homepage";
import VerifyAccount from "./components/pages/verifyPage/VerifyAccount";
import ForgotPassword from "./components/pages/forgotPasswordPage/ForgotPassword";
import ResetPassword from "./components/pages/resetPasswordPage/ResetPassword";
import SingleProduct from "./components/pages/productPage/SingleProduct";
import DashboardHome from "./components/pages/dashboard/DashboardHome";
import DbProducts from "./components/pages/dashboard/products/DbProducts";
import DbCreateProduct from "./components/pages/dashboard/products/DbCreateProduct";
import DbUpdateProduct from "./components/pages/dashboard/products/DbUpdateProduct";
import DbUsers from "./components/pages/dashboard/users/DbUsers";
import DbUpdateUser from "./components/pages/dashboard/users/DbUpdateUser";
import ReviewPage from "./components/pages/reviewPage/ReviewPage";
import DbReviews from "./components/pages/dashboard/reviews/DbReviews";
import DbReviewInfo from "./components/pages/dashboard/reviews/DbReviewInfo";
import DbTestimonials from "./components/pages/dashboard/testimonials/DbTestimonials";
import DbCreateTestimonial from "./components/pages/dashboard/testimonials/DbCreateTestimonial";
import DbUpdateTestimonial from "./components/pages/dashboard/testimonials/DbUpdateTestimonial";
import AboutUs from "./components/pages/staticPages/AboutUs";
import CategoryPageAll from "./components/pages/categoryPage/CategoryPageAll";
//dashBoard
import DbCategories from "./components/pages/dashboard/categories/DbCategories";
import DbCreateCategory from "./components/pages/dashboard/categories/DbCreateCategory";
import DbUpdateCategory from "./components/pages/dashboard/categories/DbUpdateCategory";
import DbSettings from "./components/pages/dashboard/settings/DbSettings";
import DbCreateSettings from "./components/pages/dashboard/settings/DbCreateSettings";
import DbUpdateSettings from "./components/pages/dashboard/settings/DbUpdateSettings";
import LoginPage from "./components/pages/Login/LoginPage";
import SingleBlog from "./components/pages/Blogs/SingleBlog";
import DbUpdateEnquiry from "./components/pages/dashboard/enquiry/DbUpdateEnquiry";
import DbUpdateComplaint from "./components/pages/dashboard/complaints/DbUpdateComplaint";
import DbEnquiries from "./components/pages/dashboard/enquiry/DbEnquiries";
import { DbCreateEnquiry } from "./components/pages/dashboard/enquiry/DbCreateEnquiry";
import DbCreateBlog from "./components/pages/dashboard/blog/DbCreateBlog";
import DbBlogs from "./components/pages/dashboard/blog/DbBlogs";
import DbUpdateBlog from "./components/pages/dashboard/blog/DbUpdateBlog";
import { DbComplaints } from "./components/pages/dashboard/complaints/DbComplaints";
import { DbCreateComplaint } from "./components/pages/dashboard/complaints/DbCreateComplaint";

function App() {
  const [loginState, setLoginState] = useState(false);

  useEffect(() => {
    // store.dispatch(loadUser());
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <AppContext.Provider
            value={{ loginState: loginState, setLoginState: setLoginState }}
          >
            <PreLoader />
            <div className="App">
              <ScrollTop>
                <Route path="/" component={HomePage} exact />
                <Route path="/product/:id" component={SingleProduct} />
                <Route path="/storage-rakings" component={CategoryPageAll} />
                <Route path="/review/new" component={ReviewPage} />
                <Route path="/blog/:subtitle" component={SingleBlog} />

                {/* Static Routes */}
                <Route path="/about" component={AboutUs} />

                {/* Auth Routes */}
                <Route path="/login" component={LoginPage} />
                <Route path="/verify/:token" component={VerifyAccount} />
                <Route path="/password/forgot" component={ForgotPassword} />
                <Route
                  path="/password/reset/:token"
                  component={ResetPassword}
                />

                {/* Admin Routes */}

                <ProtectedRoute
                  path="/dashboard"
                  isAdmin={true}
                  component={DashboardHome}
                  exact
                />

                {/* Products */}
                <ProtectedRoute
                  path="/admin/products"
                  isAdmin={true}
                  component={DbProducts}
                  exact
                />
                <ProtectedRoute
                  path="/admin/product"
                  isAdmin={true}
                  component={DbCreateProduct}
                  exact
                />
                <ProtectedRoute
                  path="/admin/product/:id"
                  isAdmin={true}
                  component={DbUpdateProduct}
                  exact
                />

                {/* Enquiries */}
                <ProtectedRoute
                  path="/admin/enquiries"
                  isAdmin={true}
                  component={DbEnquiries}
                  exact
                />
                <ProtectedRoute
                  path="/enquiry"
                  component={DbCreateEnquiry}
                  exact
                />
                <ProtectedRoute
                  path="/admin/enquiry/:id"
                  isAdmin={true}
                  component={DbUpdateEnquiry}
                  exact
                />

                {/* Complaints */}
                <ProtectedRoute
                  path="/admin/complaint"
                  isAdmin={true}
                  component={DbCreateComplaint}
                  exact
                />
                <ProtectedRoute
                  path="/admin/complaints"
                  isAdmin={true}
                  component={DbComplaints}
                  exact
                />
                <ProtectedRoute
                  path="/admin/complaint/:id"
                  isAdmin={true}
                  component={DbUpdateComplaint}
                  exact
                />

                {/* Blogs */}
                <ProtectedRoute
                  path="/admin/blogs"
                  isAdmin={true}
                  component={DbBlogs}
                  exact
                />
                <ProtectedRoute
                  path="/admin/blog"
                  isAdmin={true}
                  component={DbCreateBlog}
                  exact
                />
                <ProtectedRoute
                  path="/admin/blog/:id"
                  isAdmin={true}
                  component={DbUpdateBlog}
                  exact
                />

                {/* Projects */}

                {/* Users */}
                <ProtectedRoute
                  path="/admin/users"
                  isAdmin={true}
                  component={DbUsers}
                  exact
                />
                <ProtectedRoute
                  path="/admin/user/:id"
                  isAdmin={true}
                  component={DbUpdateUser}
                  exact
                />

                {/* Reviews */}
                <ProtectedRoute
                  path="/admin/reviews"
                  isAdmin={true}
                  component={DbReviews}
                  exact
                />
                <ProtectedRoute
                  path="/admin/review/:id"
                  isAdmin={true}
                  component={DbReviewInfo}
                  exact
                />

                {/* Testimonials */}
                <ProtectedRoute
                  path="/admin/testimonials"
                  isAdmin={true}
                  component={DbTestimonials}
                  exact
                />
                <ProtectedRoute
                  path="/admin/testimonial"
                  isAdmin={true}
                  component={DbCreateTestimonial}
                  exact
                />
                <ProtectedRoute
                  path="/admin/testimonial/:id"
                  isAdmin={true}
                  component={DbUpdateTestimonial}
                  exact
                />

                {/* Categories */}
                <ProtectedRoute
                  path="/admin/categories"
                  isAdmin={true}
                  component={DbCategories}
                  exact
                />
                <ProtectedRoute
                  path="/admin/category"
                  isAdmin={true}
                  component={DbCreateCategory}
                  exact
                />
                <ProtectedRoute
                  path="/admin/category/:id"
                  isAdmin={true}
                  component={DbUpdateCategory}
                  exact
                />

                {/* Settings */}
                <ProtectedRoute
                  path="/admin/settings"
                  isAdmin={true}
                  component={DbSettings}
                  exact
                />
                <ProtectedRoute
                  path="/admin/settings/create"
                  isAdmin={true}
                  component={DbCreateSettings}
                  exact
                />
                <ProtectedRoute
                  path="/admin/settings/update"
                  isAdmin={true}
                  component={DbUpdateSettings}
                  exact
                />
              </ScrollTop>
            </div>
          </AppContext.Provider>
        </Switch>
      </Router>
    </>
  );
}

export default App;
