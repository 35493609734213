import React, { Fragment, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({  isAdmin, isLogin, component: Component, ...rest }) => {


    const { isAuthenticated, loading, user } = useSelector(state => state.auth)

    return (
        <Fragment>
            {loading === false && (
                <Route
                    {...rest}
                    render={props => {

                        if (isAuthenticated === false) {
                            return <Redirect to='/login' />
                        }

                        // if (isAuthenticated && isAdmin === true && user.role !== 'admin') {
                        //     return <Redirect to='/' />
                        // }

                        // if (isAuthenticated && isLogin === true ) {
                        //     return <Redirect to='/dashboard' />
                        // }
                       

                        return <Component {...props} />
                    }}
                />
            )}
        </Fragment>
    )
}

export default ProtectedRoute
