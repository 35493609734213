export const NEW_SETTINGS_REQUEST = 'NEW_SETTINGS_REQUEST'
export const NEW_SETTINGS_SUCCESS = 'NEW_SETTINGS_SUCCESS'
export const NEW_SETTINGS_RESET = 'NEW_SETTINGS_RESET'
export const NEW_SETTINGS_FAIL = 'NEW_SETTINGS_FAIL'

export const LOAD_SETTINGS_REQUEST = 'LOAD_SETTINGS_REQUEST'
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS'
export const LOAD_SETTINGS_FAIL = 'LOAD_SETTINGS_FAIL'

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST'
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS'
export const UPDATE_SETTINGS_RESET = 'UPDATE_SETTINGS_RESET'
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'