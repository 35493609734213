export const ADMIN_TESTIMONIALS_REQUEST = 'ADMIN_TESTIMONIALS_REQUEST'
export const ADMIN_TESTIMONIALS_SUCCESS = 'ADMIN_TESTIMONIALS_SUCCESS'
export const ADMIN_TESTIMONIALS_FAIL = 'ADMIN_TESTIMONIALS_FAIL'

export const ALL_TESTIMONIALS_REQUEST = 'ALL_TESTIMONIALS_REQUEST'
export const ALL_TESTIMONIALS_SUCCESS = 'ALL_TESTIMONIALS_SUCCESS'
export const ALL_TESTIMONIALS_FAIL = 'ALL_TESTIMONIALS_FAIL'

export const NEW_TESTIMONIAL_REQUEST = 'NEW_TESTIMONIAL_REQUEST'
export const NEW_TESTIMONIAL_SUCCESS = 'NEW_TESTIMONIAL_SUCCESS'
export const NEW_TESTIMONIAL_RESET = 'NEW_TESTIMONIAL_RESET'
export const NEW_TESTIMONIAL_FAIL = 'NEW_TESTIMONIAL_FAIL'

export const DELETE_TESTIMONIAL_REQUEST = 'DELETE_TESTIMONIAL_REQUEST'
export const DELETE_TESTIMONIAL_SUCCESS = 'DELETE_TESTIMONIAL_SUCCESS'
export const DELETE_TESTIMONIAL_RESET = 'DELETE_TESTIMONIAL_RESET'
export const DELETE_TESTIMONIAL_FAIL = 'DELETE_TESTIMONIAL_FAIL'

export const UPDATE_TESTIMONIAL_REQUEST = 'UPDATE_TESTIMONIAL_REQUEST'
export const UPDATE_TESTIMONIAL_SUCCESS = 'UPDATE_TESTIMONIAL_SUCCESS'
export const UPDATE_TESTIMONIAL_RESET = 'UPDATE_TESTIMONIAL_RESET'
export const UPDATE_TESTIMONIAL_FAIL = 'UPDATE_TESTIMONIAL_FAIL'

export const TESTIMONIAL_DETAILS_REQUEST = 'TESTIMONIAL_DETAILS_REQUEST'
export const TESTIMONIAL_DETAILS_SUCCESS = 'TESTIMONIAL_DETAILS_SUCCESS'
export const TESTIMONIAL_DETAILS_FAIL = 'TESTIMONIAL_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'