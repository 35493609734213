export const ADMIN_REVIEWS_REQUEST = 'ADMIN_REVIEWS_REQUEST'
export const ADMIN_REVIEWS_SUCCESS = 'ADMIN_REVIEWS_SUCCESS'
export const ADMIN_REVIEWS_FAIL = 'ADMIN_REVIEWS_FAIL'

export const NEW_REVIEW_REQUEST = 'NEW_REVIEW_REQUEST'
export const NEW_REVIEW_SUCCESS = 'NEW_REVIEW_SUCCESS'
export const NEW_REVIEW_RESET = 'NEW_REVIEW_RESET'
export const NEW_REVIEW_FAIL = 'NEW_REVIEW_FAIL'

export const REVIEW_DETAILS_REQUEST = 'REVIEW_DETAILS_REQUEST'
export const REVIEW_DETAILS_SUCCESS = 'REVIEW_DETAILS_SUCCESS'
export const REVIEW_DETAILS_FAIL = 'REVIEW_DETAILS_FAIL'

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_RESET = 'DELETE_REVIEW_RESET'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'