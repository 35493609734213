export const NEW_PROJECT_REQUEST = 'NEW_PROJECT_REQUEST'
export const NEW_PROJECT_SUCCESS = 'NEW_PROJECT_SUCCESS'
export const NEW_PROJECT_RESET = 'NEW_PROJECT_RESET'
export const NEW_PROJECT_FAIL = 'NEW_PROJECT_FAIL'

export const ALL_PROJECTS_REQUEST = 'ALL_PROJECTS_REQUEST'
export const ALL_PROJECTS_SUCCESS = 'ALL_PROJECTS_SUCCESS'
export const ALL_PROJECTS_FAIL = 'ALL_PROJECTS_FAIL'

export const ADMIN_PROJECTS_REQUEST = 'ADMIN_PROJECTS_REQUEST'
export const ADMIN_PROJECTS_SUCCESS = 'ADMIN_PROJECTS_SUCCESS'
export const ADMIN_PROJECTS_FAIL = 'ADMIN_PROJECTS_FAIL'

export const PROJECT_DETAILS_REQUEST = 'PROJECT_DETAILS_REQUEST'
export const PROJECT_DETAILS_SUCCESS = 'PROJECT_DETAILS_SUCCESS'
export const PROJECT_DETAILS_FAIL = 'PROJECT_DETAILS_FAIL'

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_RESET = 'UPDATE_PROJECT_RESET'
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL'

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_RESET = 'DELETE_PROJECT_RESET'
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'