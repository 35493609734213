import React from "react";
import "./static.css";
import { HiUserGroup } from "react-icons/hi";
import { FaRegStar, FaRegHeart } from "react-icons/fa";
import NavBar from "../../layout/NavBar";
import Footer from "../../layout/Footer";
import ContactFrom from "../ContactFrom/ContactFrom";
import { motion } from "framer-motion";
const AboutUs = () => {
  const parentVar = {
    hidden: {
      y: 15,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 8,
        delay: 0,
        mass: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.6,
      },
    },
  };
  const childrenVar = {
    hidden: {
      y: 15,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <NavBar BrownTheme />
      <div className="about__us">
        <motion.div
          variants={parentVar}
          initial="hidden"
          animate="visible"
          className="right__bgImg"
        >
          <motion.img
            variants={childrenVar}
            src="/images/others/about__bg.jpg"
            alt="bg"
          />
          <motion.label className="reach__out">
            need to reach out to us?
          </motion.label>
        </motion.div>
        <div className="left__content">
          <div className="ab__wrapper">
            <motion.div
              variants={parentVar}
              initial="hidden"
              animate="visible"
              className="ab__div_one"
            >
              <motion.span variants={childrenVar} className="icons_wrap_new">
                <HiUserGroup />
              </motion.span>
              <motion.h4 variants={childrenVar}>About Us</motion.h4>
              <motion.p variants={childrenVar}>
                Alwanushurooq is a well know market-listed comprehensive company
                in United Arab Emirates. Alwanushurooq has emerged as a sterling
                name in the highly competitive Shelving and Racking industry.Our
                product range is manufactured using high grade material,which is
                sourced from the reliable and authentic market vendors.We
                believe that the market is in its growth stage and that we are
                uniquely positioned to success.
              </motion.p>
            </motion.div>
            <motion.div
              variants={parentVar}
              initial="hidden"
              animate="visible"
              className="ab__div_two"
            >
              <motion.span variants={childrenVar} className="icons_wrap_new">
                <FaRegStar />
              </motion.span>
              <motion.h4 variants={childrenVar}>Our Products</motion.h4>
              <motion.p variants={childrenVar}>
                Our product range is manufactured using high grade materials,
                which is sourced from the reliable and authentic market
                vendors.We believe that the market is in its growth stage and
                that we are uniquely positioned to success.
              </motion.p>
            </motion.div>
            <motion.div
              variants={parentVar}
              initial="hidden"
              animate="visible"
              className="ab__div_three"
            >
              <motion.span variants={childrenVar} className="icons_wrap_new">
                <FaRegHeart />
              </motion.span>
              <motion.h4 variants={childrenVar}>Experience</motion.h4>
              <motion.p variants={childrenVar}>
                The substantial industrial experience of our professionals
                enables us to out-roll immaculate products in the market.In
                order to achieve the pre-determined goals of our organization,
                Our skilled and qualified experts work with determination and
                complete dedication.
              </motion.p>
            </motion.div>
          </div>
        </div>
        <ContactFrom />
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
